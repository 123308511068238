.notFound {
    height: 100vh;
    width: 100%;
    background-color: #fff;
    color: #000;
    font-size: 5vmax;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
@import "../variable";

.container-menumobile {
    ul {
        list-style: none;
        margin: 60px 70px 0 10px !important;
        padding: 0;
        li {
            color: map-get($map: $colors, $key: "grey-rioclaro");
            margin: 15px 0;
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
            i {
                margin: 0 15px;
            }

            &.selected {
                color: map-get($map: $colors, $key: "orange-rioclaro");
            }
        }
    }
}

@import "../variable";

.container-menu {
    min-width: 25vw;
    border-right: 1px solid #e9e9e9c9;
    height: 100% !important;
    padding: 10px;
    ul {
        list-style: none;
        margin-left: -30px;
        li {
            margin: 15px 0;
            i {
                margin: 0 10px;
            }
        }

        a {
            text-decoration: none;
            color: map-get($map: $colors, $key: "grey-rioclaro");

            &:hover {
                text-decoration: underline;
            }
        }

        a.selected {
            color: map-get($map: $colors, $key: "orange-rioclaro");
        }
    }

    @media (min-width: 200px) {
        min-width: 25vh;
    }
}

@import "./variable.scss";

.container-router {
    width: 185vh;
    padding: 20px;
}

.container-router .filter {
    display: flex;
    width: 100%;
    margin: 10px 0;

    &.item {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid map-get($map: $colors, $key: "orange-disabled-rioclaro");
        text-align: center;
        transition: 0.3s ease;
        cursor: pointer;
        font-weight: bold;
        color: map-get($map: $colors, $key: "grey-rioclaro");

        &.selected {
            background-color: map-get($map: $colors, $key: "orange-disabled-rioclaro");
            color: #fff;
            transition: 0.3s ease;
        }
    }

    &.item:nth-child(1) {
        border-radius: 5px 0 0 5px;
        border-right: none;
    }
    &.item:nth-child(3) {
        border-radius: 0 5px 5px 0;
        border-left: none;
    }
}

@import "./variable";

.container-component-products {
    margin-top: 60px;
    width: 100%;
    color: map-get($map: $colors, $key: "grey-rioclaro");

    select {
        color: map-get($map: $colors, $key: "grey-rioclaro");
    }

    &__delivery-values {
        min-width: 50vw;
    }

    &__product-image {
        .selected {
            background-color: #f04d2817;
            border-radius: 5px;
        }

        max-width: 60vw;
        img {
            width: 70%;
            height: fit-content;
            cursor: pointer;
        }

        @media (max-width: 767px) {
            max-width: 95vw;
        }
    }
}

.container-component-products.advanced {
    min-width: 60vw;
    margin-top: 5px !important;
}

.container-component-products.format {
    min-width: 30vw;
}

.container-cardproduct {
    img {
        width: 100%;
        border-radius: 5px 5px 0 0;
        border-top: 1px solid map-get($map: $colors, $key: "border");
    }

    i {
        position: absolute;
        z-index: 10;
        right: 20px;
        color: red;
        font-size: 1.5em;
    }

    &__text {
        display: flex;
        justify-content: center;
        font-size: 1.2em;
        border: 1px solid map-get($map: $colors, $key: "border");
        border-top: hidden;
        border-radius: 0 0 5px 5px;
    }
}

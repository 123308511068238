$fonts: (
  "main-title": "Raleway-ExtraBold",
);

$colors: (
  "white-rioclaro": #fff,
  "orange-rioclaro": #f04e28,
  "orange-disabled-rioclaro": #ed866f,
  "orange-rioclaro-hover": #c23e1f,
  "green-rioclaro": #40a749,
  "grey-rioclaro": #86858a,
  "border": #e9e9e9,
  "shadow": #e9e9e9c9,
  "bg-inputs": #f1f3f8,
  "bg-1": #f1f3f8,
  "border-inputs": #e9e9e9,
  "img-to-red-ibes": invert(19%) sepia(89%) saturate(3165%) hue-rotate(344deg) brightness(86%) contrast(108%),
  "font-white": #fff,
  "font-black": #000,
  "font-main-nav": #494949,
);

$colors-rgba: (
  "white": "rgba(255,255,255,1)",
  "orange-rioclaro": "rgba(240, 78, 40, 1)",
  "back": "rgba(0, 0, 0, 1)",
);

$font-size: (
  "min": 11.5px,
  "medium": 13px,
);

$small: 576px;
$medium: 768px;
$large: 992px;
$x-large: 1200px;
$xx-large: 1400px;

@mixin keyframesrun($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}
@mixin animation11($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}
@mixin extra-small {
  @media (min-width: 0px) and (max-width: #{$small}) {
    @content;
  }
}
@mixin small {
  @media (min-width: #{$small}) and (max-width: #{$medium}) {
    @content;
  }
}
@mixin medium {
  @media (min-width: #{$medium}) and (max-width: #{$large}) {
    @content;
  }
}
@mixin large {
  @media (min-width: #{$large}) and (max-width: #{$x-large}) {
    @content;
  }
}
@mixin x-large {
  @media (min-width: #{$x-large}) and (max-width: #{$xx-large}) {
    @content;
  }
}
@mixin xx-large {
  @media (min-width: #{$xx-large}) {
    @content;
  }
}
@mixin btn-close($color-rgba) {
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' pathStroke='#{$color-rgba}' stroke='#{$color-rgba}' viewBox='-6 -7 29 29'  fill='#{$color-rgba}'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/2rem auto no-repeat;
}

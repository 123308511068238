@import "../styles/variable";

.container-products {
    width: 100%;
    padding: 20px;

    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0 20px;
        @media (max-width: 767px) {
            justify-content: flex-end;
            padding: 0;
        }

        &__search {
            display: flex;
            align-items: center;
            width: 75%;
            order: 1;
            @media (max-width: 767px) {
                width: 100%;
                order: 2;
            }
        }

        &__buttons {
            display: flex;
            justify-content: space-between;
            width: 25%;
            order: 2;
            @media (max-width: 767px) {
                width: 100%;
                justify-content: flex-end;
                order: 1;
            }
        }

        button {
            color: map-get($map: $colors, $key: "grey-rioclaro");
            i {
                margin-right: 7px;
            }
        }
    }
}


@include keyframesrun(fade-in-show) {
    0% { opacity: 0;}
    50% { opacity: 0; }
    95% { opacity: 0; }
    100% { opacity: 1;}
  }
  @include keyframesrun(fade-in-hiden) {
    from { opacity: 1; }
    to { opacity: 0; }
  }
  .simple-sidebar{
    // Sidebar Simple
	background: white;
	position: fixed;
    width: 0vh;
    transition: width 0.3s;
    top: 0px;
    bottom: 0px;
    height: 100vh;
    z-index: 4;

    &.in-right{
    	right: 0vh;
    	-webkit-box-shadow: -6px 4px 5px 0px rgba(0,0,0,0.08);
		-moz-box-shadow: -6px 4px 5px 0px rgba(0,0,0,0.08);
		box-shadow: -6px 4px 5px 0px rgba(0,0,0,0.08);
        & .btn-close{
            right: 0;
            position:absolute !important;
            z-index: 1;
        }
    }
    
    &.close > * {
    	overflow: hidden;
    	@include animation11('fade-in-hiden 0.1s linear');
    	display: none;
    }
    &.close{
        padding: 0px !important;
    }
    &.open{
    	width:25rem;
    }
    &.open > *{
    	overflow: hidden;
    	@include animation11('fade-in-show 0.01s linear');
    	display: flex;
    }
    &.open {
    	padding: calc(var(--bs-gutter-x) / 2) calc(var(--bs-gutter-x) / 2);
    }
    
    // Sidebar cart
    &.sidebar-cart{
        z-index: 10000;
        & .btn-close{
            top:1rem;
            left: calc(var(--bs-gutter-x) / 2);
            position:absolute;
        }

        & .sidebar-products{
            margin-top: 0px;
            height:calc(calc(100vh - 165px) - 74px);
            position:relative;
            overflow: auto;
            &-title{
                padding-top:20px;
                // padding-left: 40px;
                // height: 165px;
                // max-height: 165px;
            }
            &-btn{
                height: 74px;
                max-height: 74px;
            }
        }
        & .previous-price, & .new-price{
            text-align: right;
            display: block;
            width: 100%
        }
        & .previous-price{
            color: map-get($colors, 'red-ibes');
            font-weight: 300;
            font-size:map-get($font-size, 'min') !important;
        }
        & .rs-input-group{
            border-radius: 3px;

            & .input-quantity {
                input{
                    padding-top:2px;
                    padding-bottom:2px;
                }
            }
        }
    }
}
// Sidebar mobile
.sidebar-mobile{
    width:60vw !important;
    &,& .rs-drawer-content{
        background: map-get($colors, 'orange-rioclaro') !important;
    }
    & .btn-close{
        @include btn-close(map-get($colors-rgba, 'white'));
        top:1rem;
        right: 1rem;
        position:absolute;
    }
    &.open{
        width:65vw !important;
    }
    & * {
        font-size:map-get($font-size, 'min') !important;
    }
}
.rs-drawer-backdrop{
    background: #272c3654 !important;
}
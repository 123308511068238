.btn-rioclaro {
    background-color: map-get($map: $colors, $key: "orange-rioclaro");
    color: #fff;
    &:hover {
        background-color: map-get($map: $colors, $key: "orange-rioclaro-hover");
        color: #fff;
    }
}

.btn-drop {
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }
}

.btn-icon {
    color: map-get($map: $colors, $key: "grey-rioclaro");
    i {
        margin-right: 7px;
    }
}

.btn-icon-orange-large {
    color: map-get($map: $colors, $key: "orange-rioclaro");
    i {
        margin-right: 7px;
        font-size: 2.5em;
    }
}

.container-image-view {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.59);
    z-index: 1001;
    transition: 0.3s ease;

    &__bg {
        background-color: #fff;
        width: 90%;
        max-height: 95vh;
        padding: 30px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 2px solif #fff;
        img {
            width: 90%;
            max-height: 85vh;
            border-radius: 10px;
        }
    }
}

@import "bootstrap/scss/bootstrap";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "react-tabs/style/react-tabs.css";
@import "font-awesome";

@import "variable";
@import "inputs";
@import "buttons";

@import "custom.slick.scss";
@import "layout";

@import "component.navs";
@import "component.footer";
@import "component.home";
@import "component.sidebars";
@import "component.btn";
@import "component.banner";
@import "component.pos";
@import "component.modal";

@import "page.cart";
@import "page.signin";
@import "page.checkout.steps";

.ck.ck-editor {
    width: 100% !important;
    margin-top: 7px;
}
.fa {
    cursor: pointer;
}
.logo-footer{
    width: 200px;
}

.logo-social{
    width: 50px;
}

.btn-footer{
    font-size: .9em;
    width: 200px !important;
}

.webpay-logo{
    width: 200px;
}

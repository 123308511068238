.banner-slick{
    height:60vh;
    width:100%;
    object-fit:cover;
    object-position:center 11%;
    position:'relative';
    @include extra-small {
        height:40vh !important;
    }
}

.banner-top{
    & img{
        height: 40vh;
        width:100%;
        object-fit:cover;
        object-position:center 11%;
        @include extra-small {
            height:25vh !important;
        }
    }

    & h2 {
        width: 30vw;
        @include extra-small {
            font-size: 16px;
            width:40vw !important;
        }
    }
}

.banner-top-checkout {
    & img{
        height: 25vh;
        width:100%;
        object-fit:cover;
        object-position:center 11%;
    }

    & h4 {
        margin-top: -1em;
    }
}

.banner-top-container{
    & img{
        height: 50vh;
        width:100%;
        object-fit:cover;
        object-position:center 11%;
        @include extra-small {
            height:25vh !important;
        }
    }

    & h4 {
        margin-top: -1em;
    }
}

.container-assign-user {
    &.size {
        min-width: 40vw;
    }
}

.role-check {
    vertical-align: baseline !important;
}
.role-check > div {
    margin-top: 5px;
}

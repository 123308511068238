.top-nav {
	z-index: 4;
	& * {
		font-size: map-get($font-size, "min") !important;
	}
}
.sticky-nav-on header {
	margin-bottom: 4rem;
}
.main-nav {
	z-index: 4;
	min-height: 66px;

	&__search {
		position: relative;

		input {
			border: 1px solid #c3c3c3;
			border-radius: 3px;
			padding: 3px 5px;
		}
		span {
			position: absolute;
			right: 5px;
			top: 5px;
			cursor: pointer;
			img {
				width: 20px;
			}
		}
	}
	&.sticky-top {
		&,
		& + .shadow-main {
			position: fixed;
			top: 0;
			width: 100%;
		}
		@include extra-small {
			position: fixed;
			top: 0px;
			width: 100%;
		}
	}
	& .logo-main {
		width: 160px;
	}
	& * {
		font-size: map-get($font-size, "min") !important;
		color: map-get($colors, "font-main-nav");
	}
	& .nav-item {
		& .nav-link.show,
		& .nav-link.show > strong {
			color: map-get($colors, "white-rioclaro") !important;
		}
		& .nav-link.show > img {
			filter: map-get($colors, "img-to-red-ibes");
		}
		& .dropdown-menu {
			top: 99px;
			min-width: 100%;
			position: fixed;
			border-top: 10px white solid;
			border-radius: 0px;
			background: map-get($colors, "bg-1") !important;
			& .parent-category {
				min-width: 65px;
				max-width: 100%;
				display: inline-block;
			}
		}
		& .dropdown-menu.sticky-dropdown {
			top: 63px;
		}
	}
	.btn-cart,
	.btn-user {
		position: relative;
		& img {
			width: 32px;
		}
	}
	@include extra-small {
		background-color: map-get($colors, "white-rioclaro") !important;
	}
}
.shadow-main {
	-webkit-box-shadow: 0px 9px 7px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 9px 7px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 9px 7px 0px rgba(0, 0, 0, 0.1);
	width: 100%;
	position: absolute;
	top: 36.1px;
	height: 66px;
	z-index: 1;
	@include extra-small {
		top: 0px;
	}
}
.mobile-nav {
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 3;
	& * {
		font-size: map-get($font-size, "min") !important;
	}
}

.mobile-nav .btn-cart-count,
.main-nav .btn-cart-count {
	background: map-get($colors, "orange-rioclaro");
	font-size: 8px !important;
	width: 12px;
	height: 12px;
	color: white !important;
	border-radius: 50%;
	position: absolute;
	top: 10px;
	right: 0px;
}
@include extra-small {
	.mobile-nav .btn-cart-count {
		top: 0px;
	}
}

@import "../variable";

.container-header {
    width: 100%;
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
    color: map-get($map: $colors, $key: "grey-rioclaro");
    border-bottom: 1px solid map-get($map: $colors, $key: "border");
    box-shadow: 2px 2px 5px map-get($map: $colors, $key: "shadow");
    height: 8vh;

    button {
        color: map-get($map: $colors, $key: "grey-rioclaro");
        text-transform: uppercase;

        &:hover {
            color: map-get($map: $colors, $key: "font-main-nav");
        }
    }
}

.btn-close{
	@include btn-close(map-get($colors-rgba, 'black'));
	opacity: 1;
	width: 25px;
	height: 25px;
}

.btn{
	&:focus, &-close:focus{
	    outline: 0;
	    box-shadow: none !important;
	}

	&-one {
	    color: map-get($colors, 'font-white');
	    background-color: map-get($colors, 'orange-rioclaro');
	    border-color: map-get($colors, 'orange-rioclaro');
		&:hover {
		    color: map-get($colors, 'font-white');
		    background-color: map-get($colors, 'orange-rioclaro-hover');
		    border-color: map-get($colors, 'orange-rioclaro-hover');
		}
	}
	&-one.rs-btn-default:active,
	&-one.rs-btn-default:focus{
		background-color: map-get($colors, 'orange-rioclaro-hover');
	}
}

.selected-format {
	background-color: map-get($colors, 'orange-rioclaro');
	border: 5px solid map-get($colors, 'orange-rioclaro');
}

.non-selected-format {
	background-color: map-get($colors, 'white-rioclaro');
	border: 5px solid map-get($colors, 'orange-rioclaro');
}

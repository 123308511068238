.container-component-banner {
    &__banner-image {
        .selected {
            background-color: #f04d2817;
            border-radius: 5px;
        }

        max-width: 60vw;
        img {
            width: 80%;
            cursor: pointer;
        }

        @media (max-width: 767px) {
            max-width: 95vw;
        }
    }
}

.container-component-banner.size {
    @media (min-width: 767px) {
        max-width: 60vw;
    }
}

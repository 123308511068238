@media (min-width: 641px) {
    .container-product-actions,
    .container-categories-actions,
    .container-discounts-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            margin: 0 5px;
            cursor: pointer;
        }
    }
}
@media (max-width: 641px) {
    .container-product-actions,
    .container-categories-actions,
    .container-discounts-actions {
        i {
            margin-right: 15px;
            font-size: large;
            cursor: pointer;
        }
        i:last-of-type {
            margin-right: 0;
        }
    }
}

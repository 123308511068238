@import "./variable.scss";
.container-followup {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.289);
    padding: 10px;
    left: 0;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &__content {
        width: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background-color: #fff;
        border-radius: 8px;
        margin-top: 30px;

        @media (max-width: 768px) {
            width: 87%;
        }

        header {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgb(226, 226, 226);
            padding: 15px 30px 9px 30px;
            color: #727272;
            font-weight: bold;

            span {
                &:last-child {
                    cursor: pointer;
                }
            }
        }

        &__section {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            max-height: 80vh;
            overflow: auto;

            h4 {
                width: 50%;
            }

            &__step {
                border-left: 1px solid map-get($map: $colors, $key: "orange-disabled-rioclaro");
                padding: 0px 20px 20px 20px;
                position: relative;
                width: 50%;
                @media (max-width: 768px) {
                    width: 75%;
                }

                &::after {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: map-get($map: $colors, $key: "orange-disabled-rioclaro");
                    content: "";
                    position: absolute;
                    left: -5px;
                    top: -5px;
                }

                &:last-child {
                    border: none;
                    &::after {
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: map-get($map: $colors, $key: "green-rioclaro");
                        content: "";
                        position: absolute;
                        left: -5px;
                        top: -5px;
                    }
                }

                &__items {
                    margin-top: -20px;
                    margin-bottom: 30px;
                    text-transform: uppercase;
                    width: 100%;

                    &__item {
                        &:last-child {
                            font-size: 0.9em;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

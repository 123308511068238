.container-table {
    overflow: auto;
    th {
        cursor: pointer;
    }

    td,
    th {
        vertical-align: middle;
    }
}

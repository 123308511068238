.container-autocomplete {
    &__content-searched {
        border: 1px solid rgb(220, 220, 220);
        box-shadow: 3px 3px 5px #f7f7f7;

        &__item {
            &:hover {
                background-color: #f7f7f7;
                cursor: pointer;
            }
        }
    }
}

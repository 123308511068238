
.custom-slick{
	& .slick-prev {
	    left: 0px;
	    z-index: 1;
	}
	& .slick-next {
	    right: 0px;
	    z-index: 1;
	}
	& .slick-dots {
	    bottom: 20px;
	}
	& .slick-dots li{
		&.slick-active button::before{
			color:map-get($colors, 'orange-rioclaro') !important;
		}
		& button::before{
			color:map-get($colors, 'white-rioclaro') !important;
			opacity: 1;
			font-size:1rem
		}
	}

	& .slick-next:before, & .slick-prev:before {
	    display: none;
	}
	& .slick-next > img, & .slick-next{
	    height: 3rem;
	    width: 3rem;
	}
	& .slick-prev > img, & .slick-prev{
	    height: 3rem;
	    width: 3rem;
	}

	&-products{
		& .slick-list{
			margin-left: -0.2rem;
			margin-right: -0.1rem;
			// margin-right: calc(var(--bs-gutter-x) / -2);
			// margin-left: calc(var(--bs-gutter-x) / -2);
		}
		& .slick-dots {
			position: relative;
		    top: 2rem;
		}
		& .slick-next{
			right: -10px;
		}
		& .slick-prev{
			left: -9px;
		}
	}
	&-gallery{
		& .slick-prev, .slick-next {
		    width: 40px;
		    height: 40px;
		    z-index: 2;
		}
		& .slick-prev {
	    	left: 0px;
		    z-index: 1;
		}
		& .slick-next {
		    right: 0px;
		    z-index: 1;
		}
		& .slick-prev::before, & .slick-next::before{
			display: none;
		}
		& .img-product{
			max-height: 280px;
			max-width: 380px;
			width: 100%;
			object-fit: cover;
			object-position: bottom;
			margin: auto;
		}
		& .slick-dots{
			bottom: -65px;

			& li {
			    width: 65px;
			    height: 65px;
			}
			& img{
				object-fit: cover;
				height: 100%;
			}
		}
	}
}

.slider-content{
	background-color:map-get($colors, 'white-rioclaro') !important;
	border-radius: 100px;

	& .slider-title{
		color: map-get($colors, 'grey-rioclaro') !important;
		font-size: .8em;
		line-height: 1;
	}

	& .slider-main{
		color: map-get($colors, 'orange-rioclaro') !important;
		font-weight: 800;
		line-height: 1;
	}

	& .slider-button {
		@include extra-small{
			border-radius: 25px;
		}
	}
}
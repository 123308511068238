@import "./variable.scss";

.container-card-maps {
    position: absolute;
    top: 10%;
    right: 5%;
    background-color: #fff;
    border: 1px solid map-get($map: $colors, $key: "border");
    border-radius: 5px;
    box-shadow: 2px 2px 8px map-get($map: $colors, $key: "grey-rioclaro");
    max-width: 400px;
    width: 90%;
    z-index: 1000;

    &__header {
        color: map-get($map: $colors, $key: "grey-rioclaro");
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid map-get($map: $colors, $key: "border");

        &__title {
            font-size: 1.4em;
        }

        &__close {
            font-size: 1em;
            font-weight: bold;
            font-family: Arial, Helvetica, sans-serif;
            cursor: pointer;
        }
    }

    &__content {
        border-bottom: 1px solid map-get($map: $colors, $key: "border");

        &__item {
            color: map-get($map: $colors, $key: "grey-rioclaro");
            span {
                font-weight: bold;
            }
        }
    }
}

.container-dashboard {
    height: 100%;
    &__content {
        display: flex;
        height: 92vh;
        overflow: auto;
    }
    i[datatoggle="tooltip"]:hover:after
     {
      background: #333;
      background: rgba(0,0,0,.8);
      border-radius: 5px;
      border-color: #555 transparent transparent transparent;
      color: #fff;
      content: attr(title);
      padding: 5px 15px;
      margin-left:-15px;
      margin-top:-27px;
      position: absolute;
      z-index: 98;
      opacity: 0.9;
      width:fit-content;
     }

}

@media screen and (max-width: 600px) {
    table {
        width: 100%;
    }
    thead:not(.finalizar-compra) {
        display: none;
    }
    tr:nth-of-type(2n){
        background-color: inherit;
    }
    tbody td:not(.finalizar-compra) {
        display: block;
    }
    tbody tr td[data-title*="texto"]::before{
        height: 70px;
    }
    tbody tr td::before{
        content: attr(data-title);
        width: 90px;
        display: block;
        text-align: center;
        float: left;
        border-right-color: rgb(222, 226, 230);
        border-right-style: solid;
        border-right-width: 1px;
    }

    tbody tr td::before > .container-categories-actions{
        display: block;
    }
}

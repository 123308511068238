@import "./variable";

.container-drawer {
    position: fixed;
    z-index: 1000;
    right: 0;
    top: 0vh;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    transform: translateX(0%);
    transition: ease 0.3s;
    background-color: rgba(0, 0, 0, 0.13);

    &__content {
        background-color: #fff;
        padding: 10px;
        border: 1px solid map-get($map: $colors, $key: "border");
        max-width: 95%;
        min-width: 15%;
        overflow: auto;
        overflow-x: hidden;
    }

    &__close {
        position: absolute;
        right: 35px;
        top: 15px;
        color: map-get($map: $colors, $key: "grey-rioclaro");
        cursor: pointer;
        font-size: 0.9em;
    }
}

.container-formats__content .w-100 .form-control{
  //width: auto !important;
}

#root{
	&, & > .container-fluid{
		min-height: 100vh;
	}
	& > .container-fluid{
		padding-right: 12px;
		padding-left: 12px;
	}
	& .low-border{
		border-bottom: 1px solid map-get($colors, 'orange-rioclaro');
	}
	& .custom{
		&-rounded-top{
			border-top-left-radius: 10px !important;
			border-top-right-radius: 10px !important;
		}
	}
	& .icon-msg{
		width: 40%;
	}
	.moda-bootstrap{
		opacity: 1;
	}
	.m-row{
		--bs-gutter-x: 1.5rem;
		--bs-gutter-y: 0;
		margin-top: calc(var(--bs-gutter-y) * -1);
		margin-right: calc(var(--bs-gutter-x) / -2);
		margin-left: calc(var(--bs-gutter-x) / -2);
	}
}
.font-small{
	font-size: .8em;
}
.text-orange{
	color:map-get($colors, 'orange-rioclaro');
}
.text-white{
	color:map-get($colors, 'white-rioclaro');
}
.text-grey{
	color:map-get($colors, 'grey-rioclaro');
}
.text-black{
	color:map-get($colors, 'font-black');
}
.text-green{
	color:map-get($colors, 'green-rioclaro');
}
.bg-orange{
	background:map-get($colors, 'orange-rioclaro');
}
.bg-white{
	background:map-get($colors, 'white-rioclaro');
}
.bg-grey{
	background:map-get($colors, 'grey-rioclaro');
}
.bg-light{
	background:map-get($colors, 'bg-inputs');
}
.bg-i-gray{
	background:map-get($colors, 'bg-1');
}

.icon-small {
	width: 20px;
}

.shadow {
	-webkit-box-shadow: -6px 4px 5px 0px rgba(0,0,0,0.08);
	-moz-box-shadow: -6px 4px 5px 0px rgba(0,0,0,0.08);
	box-shadow: -6px 4px 5px 0px rgba(0,0,0,0.08);
}

.fade.in {
    opacity: 1 !important;
}
.rs-modal-backdrop.in {
    opacity: 0.3 !important;
}
.collapse.in {
    display: block !important;
}
.layout-two{
	& .rs-nav-item > a, & .card-profile{
		border-radius: 0.25rem !important;
		height: 4rem;
		background: white;
		display: flex;
		align-items: center;
		color: black;
		font-weight: 700;
	}
	& .rs-nav-item {
		margin-bottom: 1.5rem;
		box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
	}
	& .rs-nav-item-active a {
		background:map-get($colors, 'orange-rioclaro') !important;
		color: white !important;
	}
	& .close-session {
		background:map-get($colors, 'orange-rioclaro') !important;
	}
}


.disabled-link {
	pointer-events: none;
}

.our-pellet {
	background: url('../assets/image/png/pellet_background.png') center;
}

body {
	overflow-x: hidden;
}
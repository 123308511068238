.checkout-steps{
	& .rs-nav-item-content::before{
		background-color: map-get($colors, 'orange-rioclaro') !important;
		height: 4px !important;
		border-radius: 5px !important;
	}
	& .rs-nav-item-active > .rs-nav-item-content{
		color:map-get($colors, 'orange-rioclaro') !important;
	}
	& .rs-nav.rs-nav-horizontal .rs-nav-waterline{
		position: relative;
		bottom: 1px;
	}
	& .previous-price, & .new-price, & .price{
        text-align: right;
        display: block;
        width: 100%;
    }
    & .new-price, & .price{
        color: map-get($colors, 'orange-rioclaro');
    }
    & .previous-price{
        font-weight: 300;
        font-size:map-get($font-size, 'min') !important;
    }
}

.form-chekout{
	& .type-checkbox {
		display: flex;
	}
	& .type-checkbox .rs-radio-inline, & .cart-checkbox{
		height: 4rem;
		background: map-get($colors, 'bg-1') ;
		border: 1px solid map-get($colors, 'grey-rioclaro');
		padding: 0px 15px;
		display: flex;
		align-items: center;
		justify-content: center;

		margin-bottom: 1rem;
	}
	& label {
		font-size: map-get($font-size, 'min');
	}
	& .rs-radio-wrapper .rs-radio-inner::before {
	    border: 1px solid map-get($colors, 'orange-rioclaro') !important;
	}
	& .rs-radio-checked .rs-radio-wrapper .rs-radio-inner{
		&::before{
			background-color: #ffff !important;
		}
		&::after{
			background-color: map-get($colors, 'orange-rioclaro') !important;
			width: 12px;
			height: 12px;
			margin-top: 2px;
			margin-left: 2px;
		}
	}
	& .title-checkout{
		position:relative;
		&:after{
			content: ' ';
			background: map-get($colors, 'orange-rioclaro');
			width: 57px;
			display: inline-block;
			height: 4px;
			border-radius: 5px;
			position: absolute;
			bottom: 1rem;
		}
		& > strong {
			font-size: map-get($font-size, 'medium');
		}
	}
	& input {
		min-height: 33px !important;
		max-height: 33px !important;
		border-radius: 0;
	}
}
@import "./variable";
.container-title {
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid map-get($map: $colors, $key: "border");
    color: map-get($map: $colors, $key: "grey-rioclaro");
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.container-title.no-border {
    border-bottom: hidden;
}
